import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userActions, bookingActions } from "src/db/dbActions";
import { authService, contactService } from "src/api/apiActions";
import { LOGO_TEXT } from "src/constants";
import { DataStore } from "@aws-amplify/datastore";
import { Booking, User } from "src/models";
import { purgeData } from "src/utils";
import { Card, ListGroup } from "react-bootstrap";
import moment from "moment";
import { scoutedProfileComplete } from "src/utils";
// import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
// import { Hub } from "@aws-amplify/core";
import "src/styles/Header.css";

class Header extends React.Component {
  constructor() {
    super();
    this.bookingSub = undefined;
    this.state = {
      results: [],
      right: false,
      showAbout: false,
      bookings: [],
      newScoutMsgs: [],
      newScoutedMsgs: [],
      user: null,
    };
  }

  componentDidMount() {
    this.mounted = true;
    document.getElementById("menu-icon").addEventListener("click", function (e) {
      if (e.target.classList.contains("hamburger-toggle")) {
        e.target.children[0].classList.toggle("active");
      }
    });
    // Hub.listen("auth", (e) => {
    // if (e.event === "cognitoHostedUI") {
    //   authService
    //     .isAuthenticated()
    //     .then((user) => userActions.createNewUser({ userId: user.attributes.sub, email: user.attributes.email }));
    // }
    // switch (event) {
    // case "signIn":
    //   setUser(data);
    //   break;
    // case "signOut":
    //   setUser(null);
    //   break;
    // case "customOAuthState":
    //   setCustomState(data);
    // }
    // });

    authService
      .isAuthenticated()
      .then((res) => {
        userActions.getCurrentUser({ email: res.attributes.email }).then((currUser) => {
          this.setState({ user: currUser[0] });
          bookingActions
            .getAllBookings()
            .then((bookings) => {
              this.setState({
                bookings: bookings
                  .sort((a, b) => new Date(b._lastChangedAt) - new Date(a._lastChangedAt))
                  .filter((book) => book.scoutedId === currUser[0].id),
                newScoutMsgs: bookings.filter(
                  (book) =>
                    currUser &&
                    currUser.length > 0 &&
                    book.experience.scoutId === currUser[0].id &&
                    book.messaging &&
                    book.messaging.messages &&
                    book.messaging.messages.length > 0 &&
                    !book.messaging.seenByScout
                ),
                newScoutedMsgs: bookings.filter(
                  (book) =>
                    currUser &&
                    currUser.length > 0 &&
                    book.scoutedId === currUser[0].id &&
                    book.messaging &&
                    book.messaging.messages &&
                    book.messaging.messages.length > 0 &&
                    !book.messaging.seenByScouted
                ),
              });
            })
            .catch((e) => e);
          this.bookingSub = DataStore.observe(Booking).subscribe((b) => {
            bookingActions.getAllBookings().then((bookings) =>
              this.setState({
                bookings: bookings
                  .sort((a, b) => new Date(b._lastChangedAt) - new Date(a._lastChangedAt))
                  .filter((book) => book.scoutedId === currUser[0].id),
                newScoutMsgs: bookings.filter(
                  (book) =>
                    currUser &&
                    currUser.length > 0 &&
                    book.experience.scoutId === currUser[0].id &&
                    book.messaging &&
                    book.messaging.messages &&
                    book.messaging.messages.length > 0 &&
                    !book.messaging.seenByScout
                ),
                newScoutedMsgs: bookings.filter(
                  (book) =>
                    currUser &&
                    currUser.length > 0 &&
                    book.scoutedId === currUser[0].id &&
                    book.messaging &&
                    book.messaging.messages &&
                    book.messaging.messages.length > 0 &&
                    !book.messaging.seenByScouted
                ),
              })
            );
          });
          this.userSub = DataStore.observe(User).subscribe((u) => {
            if (u && currUser && u.element.id === currUser[0].id) {
              this.setState({ user: u.element });
            }
          });
        });
      })
      .catch((e) => e);
  }

  componentWillUnmount() {
    if (this.bookingSub) {
      this.bookingSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  /**
   * Renders items from Book an Experience link
   */
  renderExperience() {
    const { user } = this.state;
    return (
      <Row className=" mega-content px-4">
        <Container fluid className="ps-4">
          <Row>
            <Col sm={12} md={4} className="py-4 ps-0 pb-md-2 pt-md-4 ps-md-2 pb-lg-4 pt-lg-4 ps-lg-2">
              <h5>
                <Link to="/search" id="menu_simg_exp_text">
                  BOOK AN EXPERIENCE
                </Link>
              </h5>
              <Link to="/search" id="menu_simg_exp">
                <Card>
                  <Image
                    src="https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1655226140/assets/book-a-scout/book-a-scout-menu_q3ohup.jpg"
                    className="img-fluid"
                    alt="Book a Scout"
                  />
                  <Card.Body className="card-body">
                    <p className="card-text py-2">Get out there and book a Scout today</p>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col sm={12} md={4} className=" text-start pb-0 pt-0 ps-0 pb-md-2 pt-md-4 ps-md-2 pb-lg-4 pt-lg-4 ps-lg-5">
              <h5 className="pb-1 pt-1 pt-md-4 info-header">Here's why!</h5>
              <p>
                Scouted is one of the first activity marketplaces where you can search, book and enjoy totally unique,
                authentic, local experiences that have been tailor made by one of our Scouts. Find your next adventure,
                today!
              </p>
              <p>
                Need more info? Check out our <Link to="/book-an-experience/faq">FAQ page</Link>!
              </p>
            </Col>
            <Col
              sm={12}
              md={4}
              className=" text-start text-md-end pb-0 pt-0 ps-0 pb-md-2 pt-md-4 ps-md-2 pb-lg-4 pt-lg-4 ps-lg-5"
            >
              <ListGroup className=" borderless pt-1 pt-md-4 ">
                <Link to="/search" id="menu_s_exp">
                  <ListGroup.Item className=" list-group-item-action" aria-current="true">
                    <p className="menu-small-title">
                      <span className="icon-search me-2"></span>
                      SEARCH HERE!
                    </p>
                  </ListGroup.Item>
                </Link>
                <Link to="/book-an-experience/how-it-works">
                  <ListGroup.Item className=" list-group-item-action" aria-current="true">
                    <p className="menu-small-title">How It Works</p>
                  </ListGroup.Item>
                </Link>
                <Link to="/book-an-experience/faq">
                  <ListGroup.Item className=" list-group-item-action" aria-current="true">
                    <p className="menu-small-title">FAQs</p>
                  </ListGroup.Item>
                </Link>
                <Link to="/about/scouted">
                  <ListGroup.Item className=" list-group-item-action" aria-current="true">
                    <p className="menu-small-title">About Scouted</p>
                  </ListGroup.Item>
                </Link>
              </ListGroup>

              {!user && (
                <>
                  <div className="pt-4 pb-0">
                    <div className="d-flex w-100 justify-content-end">
                      <Link to="/login" id="menu_l_exp">
                        <Button className="scouted-btn scouted-btn-blue scouted-btn-sep icon-login">Login</Button>
                      </Link>
                    </div>
                  </div>
                  <div className="pt-3 pb-3">
                    <div className="d-flex w-100 justify-content-end">
                      <Link to="/create-account" id="menu_sup_exp">
                        <Button className="scouted-btn scouted-btn-gray scouted-btn-sep icon-register">Sign Up</Button>
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Row>
    );
  }

  /**
   * Renders items from Be a Scout link
   */
  renderScout() {
    const { user } = this.state;
    return (
      <Row className="mega-content px-4">
        <Container fluid className="ps-4">
          <Row className="row">
            <Col sm={12} md={4} className="py-4 ps-0 pb-md-2 pt-md-4 ps-md-2 pb-lg-4 pt-lg-4 ps-lg-2">
              <h5>
                <Link to="/create-account" id="menu_supi_s_text">
                  BECOME A SCOUT!
                </Link>
              </h5>

              <Link to="/create-account" id="menu_supi_s">
                <Card className="card">
                  <Image
                    src="https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1655226172/assets/be-a-scout/be-a-scout-menu_ceao6a.jpg"
                    className="img-fluid"
                    alt="Be A Scout"
                  />
                  <Card.Body className="card-body">
                    <p className="py-2">Get out there and Become a Scout today</p>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col sm={12} md={4} className=" text-start pb-0 pt-0 ps-0 pb-md-2 pt-md-4 ps-md-2 pb-lg-4 pt-lg-4 ps-lg-5">
              <h5 className="pb-1 pt-1 pt-md-4 info-header">Here's why!</h5>
              <p>
                Looking for a fun, flexible way to make money? Become a Scout! Scouted is a unique activity marketplace
                platform designed so anyone can easily share experiences and make money doing it!
              </p>

              <p>
                Need more info? Check out our <Link to="/be-a-scout/faq">FAQ page</Link>!
              </p>
              <p>
                Visit the <Link to="/be-a-scout/purchase-options">Scout Sign-Up Options</Link> for all the details!
              </p>
            </Col>
            <Col
              sm={12}
              md={4}
              className=" text-start text-md-end pb-0 pt-0 ps-0 pb-md-2 pt-md-4 ps-md-2 pb-lg-4 pt-lg-4 ps-lg-5"
            >
              <ListGroup className="borderless pt-1 pt-md-4">
                <Link to="/be-a-scout/how-it-works" className="list-group-item-action" aria-current="true">
                  <ListGroup.Item className="pb-0">
                    <p className="menu-small-title">How It Works</p>
                  </ListGroup.Item>
                </Link>
                <Link to="/be-a-scout/purchase-options" className="list-group-item-action" aria-current="true">
                  <ListGroup.Item className="pb-0">
                    <p className="menu-small-title">Scout Sign-Up Options</p>
                  </ListGroup.Item>
                </Link>
                <Link to="/be-a-scout/faq" className="list-group-item-action pe-0" aria-current="true">
                  <ListGroup.Item className="pb-0">
                    <p className="menu-small-title">FAQs</p>
                  </ListGroup.Item>
                </Link>
                <Link to="/be-a-scout/affiliate-program" className="list-group-item-action pe-0" aria-current="true">
                  <ListGroup.Item className="pb-0">
                    <p className="menu-small-title">Affiliate Program</p>
                  </ListGroup.Item>
                </Link>
                <Link to="/about/scouted" className="list-group-item-action pe-0" aria-current="true">
                  <ListGroup.Item className="pb-0">
                    <p className="menu-small-title">About Scouted</p>
                  </ListGroup.Item>
                </Link>
              </ListGroup>

              {!user ? (
                <>
                  <div className="pt-4 pb-0">
                    <div className="d-flex w-100 justify-content-end">
                      <Link to="/login" id="menu_l_s">
                        <Button className="scouted-btn scouted-btn-blue scouted-btn-sep icon-login">Login</Button>
                      </Link>
                    </div>
                  </div>
                  <div className="pt-3 pb-3">
                    <div className="d-flex w-100 justify-content-end">
                      <Link to="/create-account" id="menu_sup_s">
                        <Button className="scouted-btn scouted-btn-gray scouted-btn-sep icon-register">Sign Up</Button>
                      </Link>
                    </div>
                  </div>
                </>
              ) : scoutedProfileComplete(user) &&
                user.stripeId &&
                user.stripeId !== "" &&
                !user.isBusiness &&
                !user.isInterested ? (
                <div className="pt-3 pb-3">
                  <div className="d-flex w-100 justify-content-end">
                    <Button
                      onClick={() => {
                        contactService.scoutInterest({
                          name: `${user.firstName} ${user.lastName}`,
                          email: user.email,
                          phone: user.phone,
                        });
                        userActions
                          .updateUser(Object.assign({}, user, { isInterested: true }))
                          .then(() => (window.location = "/registration-progress"));
                      }}
                      className="scouted-btn scouted-btn-green scouted-btn-sep icon-register"
                    >
                      Be A Scout!
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="pt-3 pb-3">
                  <div className="d-flex w-100 justify-content-end">
                    <Link
                      to={
                        user.stripeId && user.stripeId !== "" && !user.isBusiness && scoutedProfileComplete(user)
                          ? "/registration-progress"
                          : `/profile/${user.id}`
                      }
                    >
                      <Button className="scouted-btn scouted-btn-green scouted-btn-sep icon-register">
                        Scout Status
                      </Button>
                    </Link>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Row>
    );
  }

  /**
   * Renders items from Business link
   */
  renderBusiness() {
    const { user } = this.state;
    return (
      <Row className="mega-content px-4">
        <Container fluid className="ps-4">
          <Row className="row">
            <Col sm={12} md={4} className="py-4 ps-0 pb-md-2 pt-md-4 ps-md-2 pb-lg-4 pt-lg-4 ps-lg-2">
              <h5>
                <Link to="/create-business-account" id="menu_supi_b_text">
                  BECOME A PARTNER!
                </Link>
              </h5>

              <Link to={user ? `/profile/${user.id}` : "/create-business-account"} id="menu_supi_b">
                <Card className="card">
                  <Image
                    src="https://res.cloudinary.com/dam4j2o9t/image/upload/q_auto/v1655226205/assets/business/business-menu_wvyovk.jpg"
                    className="img-fluid"
                    alt="Be A Scout"
                  />
                  <Card.Body className="card-body">
                    <p className="py-2">Become a Scouted Business Partner!</p>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col sm={12} md={4} className=" text-start pb-0 pt-0 ps-0 pb-md-2 pt-md-4 ps-md-2 pb-lg-4 pt-lg-4 ps-lg-5">
              <h5 className="pb-1 pt-1 pt-md-4 info-header">Here's why!</h5>
              <p>
                Partner with Scouted and join the fastest growing online activity marketplace in the United States! Stop
                competing with global travel companies and Google ads and let locals and travelers find and book your
                services easily!
              </p>
              <p>
                Wondering what it costs to join Scouted? Visit the{" "}
                <Link to="/business/purchase-options">Business Purchase Options</Link> for all the details!
              </p>
            </Col>
            <Col
              sm={12}
              md={4}
              className="text-start text-md-end pb-0 pt-0 ps-0 pb-md-2 pt-md-4 ps-md-2 pb-lg-4 pt-lg-4 ps-lg-5"
            >
              <ListGroup className="borderless pt-1 pt-md-4">
                <Link to="/business/purchase-options" className="list-group-item-action" aria-current="true">
                  <ListGroup.Item className="pb-0">
                    <p className="menu-small-title">Business Purchase Options</p>
                  </ListGroup.Item>
                </Link>
                <Link to="/business/partnership-program" className="list-group-item-action" aria-current="true">
                  <ListGroup.Item className="pb-0">
                    <p className="menu-small-title">Our Partnership Program</p>
                  </ListGroup.Item>
                </Link>
                <Link to="/about/scouted" className="list-group-item-action pe-0" aria-current="true">
                  <ListGroup.Item className="pb-0">
                    <p className="menu-small-title">About Scouted</p>
                  </ListGroup.Item>
                </Link>
              </ListGroup>

              {!user && (
                <>
                  {" "}
                  <div className="pt-4 pb-0">
                    <div className="d-flex w-100 justify-content-end">
                      <Link to="/business/purchase-options" id="menu_buy_b">
                        <Button className="scouted-btn scouted-btn-blue scouted-btn-sep icon-cart">Buy Now!</Button>
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Row>
    );
  }

  /**
   * Renders items from Profile link
   */
  renderProfile() {
    const { user, bookings, newScoutMsgs, newScoutedMsgs } = this.state;
    return (
      <Row className="mega-content ps-4 pe-0 w-100">
        <Container className="container-fluid w-100">
          <Row className="w-100">
            <Col sm={6} md={4} lg={3} className="py-4 ps-4">
              <h5 className="pb-1 pt-0 info-header">Welcome</h5>
              <Link to={`/profile/${user.id}`}>
                {user && user.avatar ? (
                  <Image src={user.avatar} className="img-fluid menu-profile-pic mx-auto my-2" alt="Profile" />
                ) : (
                  <FontAwesomeIcon
                    className="fa-4x silver menu-profile-pic mx-auto my-2"
                    style={{ cursor: "pointer" }}
                    icon="user-circle"
                  />
                )}
              </Link>
              <h5> {user && `${user.firstName} ${user.lastName}`}</h5>
            </Col>
            <Col sm={6} md={4} lg={3} className="pb-4 ps-4 pt-4">
              <ListGroup className="borderless">
                <h5 className="pb-1 pt-0 info-header">Your Info</h5>
                <Link to={`/profile/${user.id}`} className="list-group-item-action" aria-current="true">
                  <ListGroup.Item className="pb-0">
                    <p className="menu-small-title">Profile Details</p>
                  </ListGroup.Item>
                </Link>
                <Link
                  to={
                    (!user.stripeId && user.stripeId === "" && user.isBusiness) || !scoutedProfileComplete(user)
                      ? `/profile/${user.id}`
                      : "/registration-progress"
                  }
                  onClick={() => {
                    if (!scoutedProfileComplete(user) && (!user.stripeId || user.stripeId === "")) {
                      return;
                    } else if (!user.isBusiness && !user.isInterested) {
                      contactService.scoutInterest({
                        name: `${user.firstName} ${user.lastName}`,
                        email: user.email,
                        phone: user.phone,
                      });
                      userActions
                        .updateUser(Object.assign({}, user, { isInterested: true }))
                        .then(() => (window.location = "/registration-progress"));
                    }
                  }}
                  className="list-group-item-action pe-0"
                  aria-current="true"
                >
                  <ListGroup.Item className="pb-0">
                    <div className="d-flex w-100 justify-content-between">
                      <p className="menu-small-title">Sign Up Status</p>
                      {user && user.isScout ? (
                        <span className="ms-3 my-badge bg-success float-end rounded">Scout</span>
                      ) : user.isBusiness ? (
                        <span className="ms-3 my-badge bg-success float-end rounded">Business</span>
                      ) : (
                        <span className="ms-3 my-badge bg-success float-end rounded">User</span>
                      )}
                    </div>
                  </ListGroup.Item>
                </Link>
                <Link to="/about/scouted">
                  <ListGroup.Item className=" list-group-item-action" aria-current="true">
                    <p className="menu-small-title">About Scouted</p>
                  </ListGroup.Item>
                </Link>
                <Link to="/contact-scouted">
                  <ListGroup.Item className=" list-group-item-action" aria-current="true">
                    <p className="menu-small-title">Get Help</p>
                  </ListGroup.Item>
                </Link>
              </ListGroup>
            </Col>
            <Col sm={6} md={4} lg={3} className="ps-3 ps-sm-4 py-4 pt-sm-4 menu-border-top">
              <ListGroup className="borderless">
                <h5 className="pb-1 pt-0 info-header">Site Details</h5>
                <Link to="/booked-experiences" className="" aria-current="true">
                  <ListGroup.Item className="pb-0 list-group-item-action">
                    <div className="d-flex w-100 justify-content-between">
                      <p className="menu-small-title">Reservations</p>
                      {bookings.filter((b) => moment(b.bookingDate.start) >= moment().startOf("day")) ? (
                        <span className="ms-3 my-badge bg-danger rounded-pill float-end">
                          {bookings.filter((b) => moment(b.bookingDate.start) >= moment().startOf("day")).length}
                        </span>
                      ) : (
                        <span className="ms-3 my-badge bg-danger rounded-pill float-end">0</span>
                      )}
                    </div>
                  </ListGroup.Item>
                </Link>
                <Link to="/messages" className=" pe-0" aria-current="true">
                  <ListGroup.Item className="pb-0 list-group-item-action">
                    <div className="d-flex w-100 justify-content-between">
                      <p className="menu-small-title">Inbox</p>
                      {newScoutMsgs || newScoutedMsgs ? (
                        <span className="ms-3 my-badge bg-danger rounded-pill float-end">
                          {newScoutMsgs.length > 0
                            ? newScoutMsgs.length
                            : newScoutedMsgs.length > 0
                            ? newScoutedMsgs.length
                            : 0}
                        </span>
                      ) : (
                        <span className="ms-3 my-badge bg-danger rounded-pill float-end">0</span>
                      )}
                    </div>
                  </ListGroup.Item>
                </Link>
                <Link to="/following-scouted-local-experts">
                  <ListGroup.Item className="list-group-item-action" aria-current="true">
                    <div className="d-flex w-100 justify-content-between">
                      <p className="menu-small-title">Follows</p>
                      {user && user.following && user.following.length > 0 ? (
                        <span className="ms-5 my-badge bg-danger rounded-pill">{user.following.length}</span>
                      ) : (
                        <span className="ms-5 my-badge bg-danger rounded-pill">0</span>
                      )}
                    </div>
                  </ListGroup.Item>
                </Link>
                <Link to="/favorite-experiences">
                  <ListGroup.Item className=" list-group-item-action" aria-current="true">
                    <div className="d-flex w-100 justify-content-between">
                      <p className="menu-small-title">Favorites</p>
                      {user.favoriteExperiences && user.favoriteExperiences.length > 0 ? (
                        <span className="ms-5 my-badge bg-danger rounded-pill">{user.favoriteExperiences.length}</span>
                      ) : (
                        <span className="ms-5 my-badge bg-danger rounded-pill">0</span>
                      )}
                    </div>
                  </ListGroup.Item>
                </Link>
              </ListGroup>
            </Col>

            {user.isScout ? (
              <Col sm={6} md={12} lg={3} className="pe-0 ps-3 ps-sm-4 py-4 pt-sm-4 menu-border-top menu-border-left">
                <ListGroup className="borderless">
                  <h5 className="pb-1 pt-0 info-header">Scout Management</h5>
                  <Link to="/bookings" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0 list-group-item list-group-item-dark">
                      <p className="menu-small-title">
                        <FontAwesomeIcon icon={"ticket-alt"} /> <span className="ps-2">Your Bookings</span>
                      </p>
                    </ListGroup.Item>
                  </Link>
                  <Link to="/created-experiences" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0 list-group-item list-group-item-dark">
                      <p className="menu-small-title">
                        <FontAwesomeIcon icon={"user-edit"} /> <span className="ps-2">Your Experiences</span>
                      </p>
                    </ListGroup.Item>
                  </Link>
                </ListGroup>
              </Col>
            ) : (
              user.isBusiness &&
              user.subFeePaid && (
                <Col sm={6} md={12} lg={3} className="pe-0 ps-3 ps-sm-4 py-4 pt-sm-4 menu-border-top menu-border-left">
                  <ListGroup className="borderless">
                    <h5 className="pb-1 pt-0 info-header">Business Management</h5>

                    <Link to="/created-experiences" className="list-group-item-action" aria-current="true">
                      <ListGroup.Item className="pb-0 list-group-item list-group-item-dark">
                        <p className="menu-small-title">
                          <FontAwesomeIcon icon={"user-edit"} /> <span className="ps-2">Your Experiences</span>
                        </p>
                      </ListGroup.Item>
                    </Link>
                  </ListGroup>
                </Col>
              )
            )}
          </Row>
        </Container>
      </Row>
    );
  }

  /**
   * Render admin menu
   */
  renderAdmin() {
    const { user } = this.state;
    return (
      <Row className="mega-content px-4 w-100">
        <Container className="container-fluid">
          <Row className="row text-start">
            <Col sm={6} md={3} className="ps-4">
              <ListGroup className="borderless pt-1 pt-md-4">
                <h5 className="pb-1 pt-0 info-header">General</h5>
                {user.isAdmin && (
                  <Link to="/admin/dashboard" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Dashboard</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isFinanceAdmin) && (
                  <Link to="/admin/configuration" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Configuration</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/affiliates" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Affiliates</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isFinanceAdmin) && (
                  <Link to="/admin/coupons" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Coupons</p>
                    </ListGroup.Item>
                  </Link>
                )}
              </ListGroup>
            </Col>
            <Col sm={6} md={3} className="ps-4">
              <ListGroup className="borderless pt-1 pt-md-4">
                <h5 className="pb-1 pt-0 info-header">Site Data</h5>
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/users" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">All Users</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/experiences" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Experiences</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/messages" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Messages</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/reviews" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Reviews</p>
                    </ListGroup.Item>
                  </Link>
                )}
              </ListGroup>
            </Col>
            <Col sm={6} md={3} className="ps-4">
              <ListGroup className="borderless pt-1 pt-md-4">
                <h5 className="pb-1 pt-0 info-header">Approvals</h5>
                {(user.isAdmin || user.isFinanceAdmin) && (
                  <Link to="/admin/refund-requests" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Refund Requests</p>
                    </ListGroup.Item>
                  </Link>
                )}
                {user.isAdmin && (
                  <Link to="/admin/background-check-reviews" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Background Checks</p>
                    </ListGroup.Item>
                  </Link>
                )}
              </ListGroup>
            </Col>
            <Col sm={6} md={3} className="ps-4">
              <ListGroup className="borderless pt-1 pt-md-4">
                <h5 className="pb-1 pt-0 info-header">Requiring Attention</h5>
                {(user.isAdmin || user.isCsAdmin) && (
                  <Link to="/admin/dashboard" className="list-group-item-action" aria-current="true">
                    <ListGroup.Item className="pb-0">
                      <p className="menu-small-title">Flagged Items</p>
                    </ListGroup.Item>
                  </Link>
                )}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </Row>
    );
  }

  render() {
    const { user } = this.state;
    return (
      <Navbar fixed={window.location.pathname.includes("/edit") ? "top" : null} bg="light" variant="light" expand="lg">
        <Navbar.Brand className="me-auto">
          <Link to="/" className="me-auto">
            <Image alt="Scouted LLC Logo" className="img-fluid logo" src={LOGO_TEXT} />
          </Link>
        </Navbar.Brand>
       
        <Navbar.Toggle
          aria-controls="new-navbar"
          id="menu-icon"
          className="navbar-toggle collapsed hamburger hamburger-toggle pe-3"
        >
          <div className="hamburger-toggle">
            <div className="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </Navbar.Toggle>

        <Navbar.Collapse id="new-navbar" className="pe-3">
          <Nav className="ms-auto">
            {/* {" "}
            <Image
              className="mt-4 float-start"
              style={{ width: "12rem" }}
              src={require("../../pages/authentication/google-signin.png")}
              onClick={() => {
                Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
              }}
            /> */}

            <Nav.Item>
              <Link className="nav-link top-bar" to="/search">
                <span className="icon-search me-0 me-md-2"></span> <span className="me-0 me-md-1">Search</span>
              </Link>
            </Nav.Item>
            <Dropdown className="dropdown dropdown-mega position-static">
              <Dropdown.Toggle
                as={Nav.Item}
                style={{
                  outline: " none !important",
                  outlineOffset: "none !important",
                  boxShadow: "none !important",
                  marginRight: "none !important",
                }}
                className="navbar-btn bg-light nav-link dropdown-toggle top-bar px-0 px-sm-0 px-md-0 px-lg-2 menu-cursor"
              >
                Book an Experience
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu shadow">{this.renderExperience()}</Dropdown.Menu>
            </Dropdown>
            <Dropdown className="dropdown dropdown-mega position-static">
              <Dropdown.Toggle
                as={Nav.Item}
                style={{
                  outline: " none !important",
                  outlineOffset: "none !important",
                  boxShadow: "none !important",
                }}
                className="navbar-btn bg-light nav-link dropdown-toggle top-bar px-0 px-sm-0 px-md-0 px-lg-2 menu-cursor"
              >
                Be a Scout
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu shadow">{this.renderScout()}</Dropdown.Menu>
            </Dropdown>
            <Dropdown className="dropdown dropdown-mega position-static">
              <Dropdown.Toggle
                as={Nav.Item}
                style={{
                  outline: " none !important",
                  outlineOffset: "none !important",
                  boxShadow: "none !important",
                }}
                className="navbar-btn bg-light nav-link dropdown-toggle top-bar px-0 px-sm-0 px-md-0 px-lg-2 menu-cursor"
              >
                Business
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu shadow">{this.renderBusiness()}</Dropdown.Menu>
            </Dropdown>
            {user && (
              <Dropdown className="float-right dropdown dropdown-mega position-static menu-cursor">
                <Dropdown.Toggle
                  as={Nav.Item}
                  style={{
                    outline: " none !important",
                    outlineOffset: "none !important",
                    boxShadow: "none !important",
                  }}
                  className=" navbar-btn bg-light nav-link dropdown-toggle top-bar px-0 px-sm-0 px-md-0 px-lg-2"
                >
                  {user && user.firstName !== "" ? user.firstName : "PROFILE"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu shadow w-100">{this.renderProfile()}</Dropdown.Menu>
              </Dropdown>
            )}
            {!user && (
              <>
                <Nav.Item>
                  <Link className="nav-link top-bar" to="/login" id="menu_login">
                    Login
                  </Link>
                </Nav.Item>
              </>
            )}
            {user && user.isAdmin && (
              <Dropdown className="dropdown dropdown-mega position-static">
                <Dropdown.Toggle
                  as={Nav.Item}
                  style={{
                    outline: " none !important",
                    outlineOffset: "none !important",
                    boxShadow: "none !important",
                  }}
                  className="navbar-btn bg-light nav-link dropdown-toggle top-bar px-0 px-sm-0 px-md-0 px-lg-2 menu-cursor"
                >
                  Admin
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu shadow w-100 pb-4">{this.renderAdmin()}</Dropdown.Menu>
              </Dropdown>
            )}
            {user && (
              <Nav.Item className="dropdown dropdown-mega position-static">
                <Nav.Link
                  onClick={() => {
                    authService
                      .signOut()
                      .then(() => {
                        purgeData();
                        window.location = "/";
                      })
                      .catch(() => {});
                  }}
                  className=" top-bar px-0 px-sm-0 px-md-0 px-lg-2"
                >
                  Logout
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Link className="nav-link top-bar" to="/contact-scouted" id="menu_contact">
                Contact
              </Link>
            </Nav.Item>
             {/* REMOVING BLOG LINK
            <Nav.Item>
              <Link className="nav-link top-bar" to="/blog" id="menu_blog">
                Blog
              </Link>
            </Nav.Item>
             */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
